import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { Suspense } from "solid-js";
import {
  ColorModeProvider,
  ColorModeScript,
  cookieStorageManagerSSR,
} from "@kobalte/core";
import "./global.css";
import { getCookie } from "vinxi/http";
import { isServer } from "solid-js/web";

import "@fontsource/tilt-warp";
import { withSentryRouterRouting } from "@sentry/solidstart/solidrouter";
import { posthog } from "posthog-js";

function getServerCookies() {
  "use server";
  const colorMode = getCookie("kb-color-mode");
  return colorMode ? `kb-color-mode=${colorMode}` : "";
}

if (
  !isServer &&
  !window.location.host.includes("127.0.0.1") &&
  !window.location.host.includes("localhost")
) {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: "https://eu.i.posthog.com",
    person_profiles: "identified_only",
  });
}

const SentryRouter = withSentryRouterRouting(Router);

export default function App() {
  const storageManager = cookieStorageManagerSSR(
    isServer ? getServerCookies() : document.cookie
  );

  return (
    <SentryRouter
      root={(props) => (
        <>
          <ColorModeScript storageType={storageManager.type} />
          <ColorModeProvider storageManager={storageManager}>
            <Suspense>{props.children}</Suspense>
          </ColorModeProvider>
        </>
      )}
    >
      <FileRoutes />
    </SentryRouter>
  );
}
