import { createAsync, type RouteDefinition } from "@solidjs/router";
import { For, Suspense } from "solid-js";
import {
  MetricCard,
  ProgressMeasure,
} from "~/components/custom/metric-component";
import { getStatsFromUser } from "~/lib/db/stats";
import { EmailChart } from "~/components/custom/email-chart";
import Mail from "lucide-solid/icons/mail";
import Send from "lucide-solid/icons/send";
import Inbox from "lucide-solid/icons/inbox";
import Users from "lucide-solid/icons/users";
import Settings from "lucide-solid/icons/settings";
import ChartBar from "lucide-solid/icons/chart-bar";
import Check from "lucide-solid/icons/check";
import User from "lucide-solid/icons/user";

const sections = [
  { icon: <ChartBar class="w-5 h-5" />, label: "Dashboard", active: true },
  { icon: Send, label: "Campaigns" },
  { icon: Inbox, label: "Templates" },
  { icon: Users, label: "Subscribers" },
  { icon: Settings, label: "Settings" },
] as const;

const active = false;

export const route: RouteDefinition = {
  preload: async () => {
    "use server";

    /**
     * @debt hardcoded user id
     */
    return getStatsFromUser("rc-123");
  },
};
export default function Metrics() {
  /**
   * @debt hardcoded user id
   */
  const stats = createAsync(() => getStatsFromUser("rc-123"));

  return (
    <div class="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors">
      {/* Sidebar */}
      <div class="fixed inset-y-0 left-0 w-64 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700">
        <div class="flex items-center gap-2 px-6 py-4 border-b border-gray-200 dark:border-gray-700">
          <Mail class="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
          <span class="font-semibold text-xl dark:text-white">W8</span>
        </div>
        <nav class="p-4">
          <div
            class={`flex items-center gap-3 px-4 py-2 rounded-lg cursor-pointer mb-1 ${
              active
                ? "bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400"
                : "text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50"
            }`}
          >
            <ChartBar class="w-5 h-5" />
            <span class="font-medium">Metrics</span>
          </div>
          <div
            class={`flex items-center gap-3 px-4 py-2 rounded-lg cursor-pointer mb-1 ${
              active
                ? "bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400"
                : "text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50"
            }`}
          >
            <User class="w-5 h-5" />
            <span class="font-medium">Subscribers</span>
          </div>
          <div
            class={`flex items-center gap-3 px-4 py-2 rounded-lg cursor-pointer mb-1 ${
              active
                ? "bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400"
                : "text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50"
            }`}
          >
            <Settings class="w-5 h-5" />
            <span class="font-medium">Account</span>
          </div>
        </nav>
      </div>

      {/* Main Content */}
      <div class="ml-64 p-8">
        <div class="max-w-6xl mx-auto">
          <div class="flex justify-between items-center mb-8">
            <div>
              <h1 class="text-2xl font-bold text-gray-900 dark:text-white">
                Email Analytics
              </h1>
              <p class="text-gray-500 dark:text-gray-400 mt-1">
                Track your email campaign performance
              </p>
            </div>
            <div class="flex gap-4 items-center">
              <select class="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg px-4 py-2 text-sm text-gray-900 dark:text-gray-300">
                <option>Last 7 days</option>
                <option>Last 30 days</option>
                <option>Last 90 days</option>
              </select>
              <button class="bg-indigo-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-indigo-700 transition-colors">
                Export Report
              </button>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            <MetricCard
              title="Total Sent"
              value="1,286"
              trend={12.5}
              icon={Send}
              color="bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400"
            />
            <MetricCard
              title="Delivered"
              value="1,254"
              trend={10.8}
              icon={Inbox}
              color="bg-green-50 dark:bg-green-900/20 text-green-600 dark:text-green-400"
            />
            <MetricCard
              title="Opened"
              value="753"
              trend={-2.4}
              icon={Mail}
              color="bg-purple-50 dark:bg-purple-900/20 text-purple-600 dark:text-purple-400"
            />
            {/* <MetricCard
              title="Verified"
              value="753"
              trend={-2.4}
              icon={Check}
              color="bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400"
            /> */}

            <ProgressMeasure
              title="Verified"
              value="753"
              trend={-2.4}
              icon={Check}
              color="bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400"
            />
          </div>

          <EmailChart />

          <div class="grid grid-cols-2 gap-6 mt-8">
            <div class="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
              <h3 class="text-lg font-semibold mb-4 dark:text-white">
                Top Performing Emails
              </h3>
              <div class="space-y-4">
                {[
                  { title: "Welcome Series: Day 1", rate: "68%" },
                  { title: "Monthly Newsletter", rate: "62%" },
                  { title: "Product Update", rate: "57%" },
                ].map((email) => (
                  <div class="flex justify-between items-center">
                    <span class="text-gray-600 dark:text-gray-300">
                      {email.title}
                    </span>
                    <span class="text-sm font-medium text-indigo-600 dark:text-indigo-400">
                      {email.rate}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div class="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
              <h3 class="text-lg font-semibold mb-4 dark:text-white">
                Engagement by Time
              </h3>
              <div class="space-y-4">
                {[
                  { time: "9:00 AM - 12:00 PM", rate: "32%" },
                  { time: "12:00 PM - 3:00 PM", rate: "28%" },
                  { time: "3:00 PM - 6:00 PM", rate: "24%" },
                ].map((slot) => (
                  <div class="flex justify-between items-center">
                    <span class="text-gray-600 dark:text-gray-300">
                      {slot.time}
                    </span>
                    <span class="text-sm font-medium text-indigo-600 dark:text-indigo-400">
                      {slot.rate}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
